<template>
	<BySlider v-show="combo.isShowFlavor">
		<div>
			<div v-for="detail in combo.detailList">
				<ByRadio
					class="detail_name__text"
					v-if="combo.mostQuantity === 1"
					:name="String(detail.id)"
					:title="detail.itemName"
					:required="combo.leastQuantity === 1"
					v-model="combo.select[0]"
					:disabled="!detail.availableForSale"
				>
					<span class="flavor-price">
						{{
							detail.itemName && detail.price
								? `+$ ${$stepPrice(detail.price)}`
								: ''
						}}
					</span>
				</ByRadio>

				<ByCheckbox
					class="detail_name__text"
					:class="{ disabled: maxComboCount(combo, detail.id) === 0 }"
					v-if="combo.mostQuantity > 1 || combo.mostQuantity === null"
					:name="String(detail.id)"
					:title="detail.itemName"
					:disabled="
						!detail.availableForSale || maxComboCount(combo, detail.id) === 0
					"
					:value="combo.select.includes(String(detail.id))"
					@change="
						(value, ev) => handleChangeComboContent(value, ev, combo, detail)
					"
				>
					<div style="display: flex; align-items: center">
						<ByAmountPicker
							class="product-amount"
							:min="1"
							:max="maxComboCount(combo, detail.id)"
							v-model="detail.count"
							v-if="combo.select.includes(String(detail.id))"
							@onIncrease="updateComboDetailCount(detail, 'onIncrease')"
							@onMinus="updateComboDetailCount(detail, 'onMinus')"
              @updateCount="updateComboDetailCount(detail, 'onInputUpdate', detail.count)"
						/>
						<span class="flavor-price" style="width: 80px; text-align: right">
							{{
								detail.itemName && detail.price
									? `+$ ${$stepPrice(detail.price)}`
									: ''
							}}
						</span>
					</div>
				</ByCheckbox>

				<div v-for="i in detail.count" :key="'detailCount' + i">
					<div
						class="option__name__expend"
						v-if="
							combo.select.includes(String(detail.id)) &&
							detail.optionList &&
							detail.optionList.length
						"
						@click="handleComboDetailIsShowFlavor(detail, i - 1)"
					>
						<span>選擇口味-{{ detail.itemName }}({{ i }})</span>
						<img
							src="../../../assets/back.svg"
							class="arrow"
							:class="{ active: detail.isShowFlavor[i - 1] }"
						/>
					</div>
					<BySlider
						v-if="
							combo.select.includes(String(detail.id)) &&
							detail.optionList &&
							detail.optionList.length > 0
						"
						v-show="detail.isShowFlavor[i - 1]"
					>
						<Flavor upgrade :data="detail.optionSaveList[i - 1] || []" />
					</BySlider>
				</div>
			</div>
		</div>
	</BySlider>
</template>

<script>
import Flavor from '@/views/buy/Detail/Flavor'

export default {
	components: { Flavor },
	props: {
		_combo: {
			type: Object,
		},
	},
	data() {
		return {}
	},
	computed: {
		combo: {
			get() {
				return this._combo
			},
			set(value) {
				this.$emit('update:_combo', value)
			},
		},
	},
	methods: {
		maxComboCount(combo, comboDetailId) {
			if (combo.mostQuantity) {
				let count = 0
				combo.detailList.forEach(detail => {
					if (
						combo.select.includes(String(detail.id)) &&
						comboDetailId !== detail.id
					) {
						count += detail.count
					}
				})
				return combo.mostQuantity - count
			}
		},
		handleChangeComboContent(value, ev, combo, comboDetail) {
			if (combo.select.includes(value)) {
				comboDetail.count = 1
				combo.select = combo.select.filter(v => v !== value)
			} else {
				combo.select.push(value)
			}
		},
		updateComboDetailCount(comboDetail, status, inputCount) {
			switch (status) {
				case 'onIncrease':
					comboDetail.isShowFlavor.push(true)
					comboDetail.optionSaveList.push(
						JSON.parse(JSON.stringify(comboDetail.optionList)),
					)
					break;
				case 'onMinus':
					comboDetail.isShowFlavor.pop()
					comboDetail.optionSaveList.pop()
					break;
        case 'onInputUpdate':
          // 计算需要添加或删除的数量
          const delta = inputCount - comboDetail.isShowFlavor.length;
          if (delta > 0) {
            for (let i = 0; i < delta; i++) {
              comboDetail.isShowFlavor.push(true);
              comboDetail.optionSaveList.push(
                  JSON.parse(JSON.stringify(comboDetail.optionList)),
              );
            }
          } else {
            for (let i = 0; i < Math.abs(delta); i++) {
              comboDetail.isShowFlavor.pop();
              comboDetail.optionSaveList.pop();
            }
          }
          break;
			}
		},
		handleComboDetailIsShowFlavor(comboDetail, index) {
			console.log(comboDetail.isShowFlavor[index])
			this.$set(
				comboDetail.isShowFlavor,
				index,
				!comboDetail.isShowFlavor[index],
			)
		},
	},
}
</script>

<style scoped lang="sass">
::v-deep .by-checkbox label
  width: 100%
%comboName
  font-size: 16px
  font-weight: bold
  display: flex
  align-items: center

.option__name__expend, .combo__name__expend
  font-size: 16px
  font-weight: bold
  height: 25px
  flex: 1
  padding: 0 20px
  cursor: pointer
  margin-top: 20px
  display: flex
  justify-content: space-between
  position: relative
  outline: none

  img
    transition: all 0.3s
    transform: rotate(-90deg)
    width: 18px
    height: 18px

.tag
  margin: 0px 0
  font-size: 12.5px
  padding: 0 0px 0 5px
  color: red
  position: absolute
  top: -3px

.quantityText
  color: gray
  margin-left: 5px
  font-size: 12.5px
  font-weight: normal

  &.active
    color: red
    margin-left: 10px

.arrow
  &.active
    transform: rotate(90deg)

.errorValidate
  font-size: 12.5px
  color: red
  margin-left: 20px

.combo
  margin: 20px

  &__item
    padding-top: 10px

    &__name

      padding-bottom: 10px
      font-size: 16px
      font-weight: bold

      > .price
        margin-left: auto

      margin-bottom: 10px

    .detail_name__text
      font-weight: normal
      margin: 12px 0 12px 0

      &.disabled
        filter: grayscale(1)
        opacity: 0.3

    > .name
      @extend %comboName
      font-weight: normal
      margin: 12px 0

      &__text
        display: flex
        flex-direction: column

        align-items: flex-start
        flex: 1

        .flavor-price
          display: flex
          align-items: center

        .content
          width: 100%
          margin-top: 10px
          font-size: 14px
          color: #8E8E8E

        &::v-deep
          label
            flex: 1

      &__black
        width: 18px
        height: 18px
        margin-left: 8px

      &__expend
        height: 18px
        width: 18px
        cursor: pointer
        margin-left: 8px

        img
          transition: all 0.3s
          transform: rotate(-90deg)
          width: 100%
          height: 100%

          &.active
            transform: rotate(90deg)

      .flavor
        padding: 12px
        background: rgba(#000, .02)
        border-radius: 8px

        &::v-deep
          .flavor
            width: 100%
            padding-top: 12px

            &:first-child
              padding-top: 0

            &:last-child
              border-bottom: 0
              padding-bottom: 0
</style>
