<template>
	<div :class="{ upgrade: upgrade }">
		<div class="flavor" v-for="(item, index) in data" :key="index">
			<div class="flavor-name">
				{{ item.name
				}}<span v-if="item.required" class="flavor-required--must">*</span>
			</div>
			<div class="errorValidate" v-if="item.errorValidate">
				- 請選擇{{ item.name }}
			</div>
			<div
				class="flavor-option"
				v-for="(option, o_index) in item.optionDetailList"
				:key="'options-' + o_index"
			>
				<template v-if="canBuy">
					<ByRadio
						v-if="!item.type"
						:name="String(option.id)"
						:title="option.name"
						:required="item.required"
						v-model="item.count"
					>
						<span class="flavor-price">
							{{
								option.price
									? `+$ ${$stepPrice(option.price * option.count)}`
									: ''
							}}
						</span>
					</ByRadio>
					<ByCheckbox
						v-else
						:name="String(option.id)"
						:value="item.count.includes(String(option.id))"
						:title="option.name"
						@change="handleChange(index, String(option.id))"
					>
						<span class="flavor-price" :class="{ hide: !option.price }">
							+$ {{ $stepPrice(option.price * option.count) }}
						</span>
					</ByCheckbox>
				</template>
				<div v-else>{{ option.name }}</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Flavor',
	props: {
		data: {
			type: Array,
			default: () => [],
		},
		upgrade: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {}
	},
	computed: {
		canBuy() {
			return this.$store.getters.canBuy
		},
	},
	methods: {
		handleChange(index, optionId) {
			const selectOptionIds = this.data[index].count
			const selectedIndex = selectOptionIds.findIndex(e => e === optionId)
			const hasOption = selectedIndex !== -1
			if (hasOption) {
				selectOptionIds.splice(selectedIndex, 1)
			} else {
				selectOptionIds.push(optionId)
			}
		},
	},
	// created() {},
	// mounted() {},
	// beforeDestroy () {},
}
</script>

<style lang="sass" scoped>
.flavor
  display: block
  width: calc(100% - 40px)
  padding: 20px 0
  margin: 0 auto
  border-bottom: 1px solid #D8D8D8

  &-name
    font-size: 16px
    font-weight: bold
    display: flex
    align-items: center
    position: relative

  &-tag
    margin: 10px 0


  &-required
    &--must
      padding: 0 0px 0 5px
      color: red
      font-weight: bold
      font-size: 13px
      transform: translate(0px,-6px)


    &--not
      font-weight: normal
      margin-left: 5px
      font-size: 14px
      padding: 4px
      border-radius: 4px
      background: #F3F3F3
      color: #434343

  &-option
    padding-top: 10px
    color: #434343

  &-price
    min-width: 45px
    text-align: right

  &-control
    display: flex
    align-items: center
    justify-content: space-between
    width: 110px
    font-weight: bold

.upgrade
  margin: 0px 20px 20px 20px

  /*border: 1px dashed rgba(0,0,0,0.2)*/
  background: rgba(0,0,0,0.03)
  .flavor
  .flavor-name
    font-size: 16px
  &::v-deep
    .radio
      min-width: 22px
      width: 22px
      height: 22px
    .title,.flavor-price,.number
      font-size: 16px

    input[type="radio"]:checked
      &+.radio
        &:before
          width: 12px
          height: 12px

    .by-checkbox .checkbox
      min-width: 22px
      width: 22px
      height: 22px
    .by-checkbox input[type="checkbox"]:checked
      &+.checkbox

        &:before
          content: ''
          border: solid #fff
          border-width: 0 3px 3px 0
          padding: 3px
          margin-bottom: 3px
          transform: rotate(45deg)
    .by-checkbox>label,.by-radio>label
      width: 50%
    .amount
      width: 85px
      .number
        width: 35px
      .minus,.plus
        width: 25px




.errorValidate
  font-size: 14px
  color: red
  margin-top: 5px
</style>
